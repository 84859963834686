var render = function render(){var _vm=this,_c=_vm._self._c;return (
    ['COACH', 'MANAGER'].includes(_vm.$user.role) ||
    (_vm.$user.context.role === 'ADMIN' &&
      _vm.$user.context.resourceType === 'SCHOOL')
  )?_c('form-item',{attrs:{"label":"Cursusgroep","prop":"followingGroupsIds"}},[_c('sb-info',[(_vm.$user.role === 'COACH')?_c('p',[_vm._v(" Voeg hier de leerling direct toe aan een cursusgroep. ")]):_c('p',[_vm._v(" Het is optioneel om hier de gebruiker direct toe te voegen aan een cursusgroep. ")])]),_c('div',{staticClass:"group-inputs"},[_c('i-select',{staticStyle:{"max-width":"300px","min-width":"200px"},attrs:{"id":"CourseGroupInput","placeholder":_vm.$apollo.queries.groups.loading
          ? 'Bezig met ophalen...'
          : 'Kies een groep',"required":_vm.$user.role === 'COACH',"disabled":_vm.$apollo.queries.groups.loading ||
        _vm.disabledFields.includes('followingGroupsIds')},model:{value:(_vm.groupId),callback:function ($$v) {_vm.groupId=$$v},expression:"groupId"}},[(!_vm.selectedGroupId)?_c('i-option',{staticStyle:{"opacity":"0.3"},attrs:{"value":''}},[_vm._v(" Geen Cursusgroep ")]):_vm._e(),_vm._l((_vm.groups),function(group){return _c('i-option',{key:group.id,attrs:{"value":group.id}},[_vm._v(" "+_vm._s(group.name)+" ")])})],2),(
        _vm.$user.role === 'MANAGER' &&
        !_vm.disabledFields.includes('followingGroupsIds')
      )?[_vm._v(" of "),_c('i-button',{on:{"click":function($event){return _vm.$emit('open')}}},[_vm._v(" Nieuwe groep ")])]:_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }